<template>
    <v-container>
        <v-card class="mb-5">
            <v-card-title>Get Video Links from Playlist</v-card-title>
            <v-card-text>
                <v-text-field v-model="playlistUrl" label="Playlist URL" outlined required></v-text-field>
                <v-btn @click="getVideoLinks" color="secondary">
                    <v-progress-circular v-if="isLoading" indeterminate size="18"></v-progress-circular>
                    <span v-else>Get Video Links</span>
                </v-btn>

                <!-- Displaying video URLs -->
                <v-list dense v-if="videoUrls.length > 0">
                    <v-subheader>Video URLs</v-subheader>
                    <v-list-item v-for="(url, index) in videoUrls" :key="index">
                        <v-list-item-content>{{ url }}</v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>
        </v-card>
        <!-- Update Multiple Lessons -->
        <v-card class="mb-5">
            <v-card-title>Update Multiple Lessons</v-card-title>
            <v-card-text>
                <v-textarea v-model="playlist.videoUrls" label="Video URLs (one per line)" outlined></v-textarea>
                <v-btn @click="updateLessons" color="secondary">
                    <v-progress-circular v-if="isUpdatingLessons" indeterminate size="18"></v-progress-circular>
                    <span v-else>Update Lessons</span>
                </v-btn>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";


export default {
    data() {
        return {
            singleLesson: {
                videoUrl: ''
            },
            playlist: {
                videoUrls: ''
            },
            isUpdatingLesson: false,
            isUpdatingLessons: false,
            playlistUrl: '',
            videoUrls: [],
            isLoading: false
        };
    },
    computed: {
        ...mapGetters([
            'isUserAuthenticated',
            'user',
            'usageCount',
            'plan',
            'isOriginalPlanActive',
            'maxUsage',
            'hasExceededUsageLimit',
            'isMaxed'
        ]),
    },
    methods: {
        async getVideoLinks() {
            if (!this.playlistUrl) {
                alert("Please enter a playlist URL");
                return;
            }

            this.isLoading = true;
            this.videoUrls = []; // Clear previous results

            try {
                const response = await axios.get(`${process.env.VUE_APP_API_ADDRESS}/playlists/urls`, {
                    params: { playlist_url: this.playlistUrl }
                });

                this.videoUrls = response.data;
            } catch (error) {
                console.error("Error fetching video links: ", error);
                alert("Failed to fetch video links");
            } finally {
                this.isLoading = false;
            }
        },
        async updateLessons() {
            this.isUpdatingLessons = true;
            try {
                const videoUrlsArray = this.playlist.videoUrls.split('\n');

                // You might need to adjust this depending on how you're storing the token.
                const token = this.user.accessToken;
                const headers = {
                    'Authorization': `Bearer ${token}`
                };

                const response = await axios.post(`${process.env.VUE_APP_API_ADDRESS}/lessons`, { video_urls: videoUrlsArray }, { headers: headers });
                // Handle response
                console.log(response);
            } catch (error) {
                console.error("Error updating lessons: ", error);
                alert("Error updating lessons"); // added alert
            } finally {
                this.isUpdatingLessons = false;
            }
        }

    }
};
</script>
