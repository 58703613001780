// store/index.js
import Vue from "vue";
import Vuex from "vuex";
import rootMutations from "./mutations.js";
import rootActions from "./actions.js";
import rootGetters from "./getters.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    usageCount: 0,
    authStatusChecked: false,
    plan: null,
    planHistory: [],
    timeout: null
  },
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions
});