export const SET_TIMEOUT = 'SET_TIMEOUT';
export const CLEAR_TIMEOUT = 'CLEAR_TIMEOUT';

export default {
  setUserData(state, payload) {
    state.user = payload;
  },
  setUsageCount(state, usageCount) {
    state.usageCount = usageCount;
  },
  setPlan(state, plan) {
    state.plan = plan;
  },
  setAuthStatusChecked(state, value) {
    state.authStatusChecked = value;
  },
  setPlanHistory(state, history) {
    state.planHistory = history;
  },
  // Added inactivity mutations
  [SET_TIMEOUT](state, timeout) {
    state.timeout = timeout;
  },
  [CLEAR_TIMEOUT](state) {
    clearTimeout(state.timeout);
    state.timeout = null;
  }
};
