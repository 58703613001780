export default {
  isUserAuthenticated(state) {
    return !!state.user;
  },
  isUserAdmin(state) {
    // This is a quick and dirty way to make me admin for sam.gabrail@tekanaid.com signing in with my Google account.
    const currentUser = state.user;
    const myAdminUserId = 'sam.gabrail@tekanaid.com';
    return currentUser ? currentUser.email === myAdminUserId : false;
  },
  user(state) {
    return state.user ? state.user : null;
  },
  usageCount(state) {
    return state.usageCount;
  },
  plan(state) {
    return state.plan;
  },
  isOriginalPlanActive(state) {
    const today = new Date();
    return state.planHistory.some(entry => {
      const expiryDate = entry.expiryDate.toDate();
      return today <= expiryDate;
    });
  },
  maxUsage(state, getters) {
    if (getters.isOriginalPlanActive) {
      const activePlans = state.planHistory.filter(entry => {
        const today = new Date();
        const expiryDate = entry.expiryDate.toDate();
        return today <= expiryDate;
      });
      // Find the plan with the highest rank among active plans
      const highestRank = Math.max(...activePlans.map(plan => plan.rank));
      // Find the plan name corresponding to this rank
      const highestPlan = activePlans.find(plan => plan.rank === highestRank).plan;
      return getMaxUsage(highestPlan);
    }
    return getMaxUsage(state.plan);
  },
  // Check if the user has exceeded the usage limit
  hasExceededUsageLimit(state, getters) {
    return state.usageCount > getters.maxUsage;
  },

  isMaxed(state, getters) {
    return state.usageCount >= getters.maxUsage;
  },
};

function getMaxUsage(plan) {
  switch (plan) {
    case "Pro Monthly":
      return process.env.VUE_APP_MAX_PRO_USAGE;
    case "Premium Monthly":
      return process.env.VUE_APP_MAX_PREMIUM_USAGE;
    default:
      return process.env.VUE_APP_MAX_FREE_USAGE;
  }
}