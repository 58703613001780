import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Hotjar from '@hotjar/browser';
import VueYoutube from 'vue-youtube-embed';
import Toasted from 'vue-toasted';
// Import Firebase and Analytics (Modular SDK v10.1.0)
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';

import axios from 'axios';
Vue.config.productionTip = false;
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXJbTeVkxdoszyF6wOjAbYpk2a5d1dJdY",
  authDomain: "blog-tekanaid.firebaseapp.com",
  projectId: "blog-tekanaid",
  storageBucket: "blog-tekanaid.appspot.com",
  messagingSenderId: "981938205594",
  appId: "1:981938205594:web:424e5f3b8ea18f14ff8023",
  measurementId: "G-D8VJTM037L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics
const analytics = getAnalytics(app);

const auth = getAuth();

Vue.use(Toasted, {
  duration: 2000
});
Vue.use(VueYoutube)

const siteId = 3654393;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

new Vue({
  el: "#app",
  router,
  store,
  vuetify,
  template: "<App/>",
  render: h => h(App),
  created() {
    onAuthStateChanged(auth, async (user) => {

      this.$store.dispatch('setUserData', user);
      this.$store.dispatch('markAuthStatusChecked');

      if (user) {
        this.$store.dispatch('fetchUserData', user.uid);

        // Check if the user's email is verified
        if (user.emailVerified) {

          // Initialize Firestore
          const db = getFirestore();
          const userDocRef = doc(db, 'users', user.uid);

          // Fetch the current user document
          const userDocSnap = await getDoc(userDocRef);

          // Check if Stripe customer has been created
          if (userDocSnap.exists() && !userDocSnap.data().stripe_customer_id) {


            // Create Stripe customer
            try {
              const token = store.getters.user.accessToken;
              const headers = {
                'Authorization': `Bearer ${token}`
              };
              const stripeResponse = await axios.post(`${process.env.VUE_APP_API_ADDRESS}/create_stripe_customer`, {
                user_id: user.uid,
                email: user.email,
                subscription_level: 'Free',
              }, {
                headers: headers
              });

              // Update Firestore document to store the Stripe customer ID
              await updateDoc(userDocRef, {
                stripe_customer_id: stripeResponse.data.id,
                emailVerified: true
              });

            } catch (error) {
              console.error('Error creating Stripe customer:', error);
            }
          } else if (userDocSnap.exists() && userDocSnap.data().emailVerified !== true) {
            // Update just the emailVerified flag if needed
            await updateDoc(userDocRef, {
              emailVerified: true
            });
          }
          // Add user to convertkit
          try {
            const token = store.getters.user.accessToken;
            const headers = {
              'Authorization': `Bearer ${token}`
            };
            await axios.post(`${process.env.VUE_APP_API_ADDRESS}/convertkit/add_user`, {
              email: user.email,
              first_name: user.displayName
            }, {
              headers: headers
            });
          } catch (error) {
            console.error('Error adding user to ConvertKit:', error);
          }
        }
      }
    });
  }
});
