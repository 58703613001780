<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to the VidToBlog AI App
        </h1>

        <p class="subheading font-weight-regular">
          Convert your YouTube videos into a Blog posts with AI
        </p>
        <p>
          You can learn more by going to our
          <router-link to="/about">About</router-link> page
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Welcome",

  data: () => ({})
};
</script>
