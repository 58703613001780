import router from '@/router/index';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { SET_TIMEOUT, CLEAR_TIMEOUT } from './mutations';
import { signOut, getAuth } from 'firebase/auth';

const INACTIVITY_TIMEOUT = process.env.VUE_APP_TIMEOUT || 3600000;  // 1 hour as default
async function fetchUserDataFromFirestore(userId, field) {
  const db = getFirestore();
  const userRef = doc(db, "users", userId);
  const userSnap = await getDoc(userRef);
  return userSnap.exists() ? userSnap.data()[field] : null;
}

export default {
  async setUserData(context, payload) {
    context.commit('setUserData', payload);
  },
  async fetchUserData(context, userId) {
    const usageCount = await fetchUserDataFromFirestore(userId, 'usage_count');
    context.commit('setUsageCount', usageCount || 0);

    const plan = await fetchUserDataFromFirestore(userId, 'subscription_level');
    context.commit('setPlan', plan || "Free");

    const planHistory = await fetchUserDataFromFirestore(userId, 'planHistory');
    context.commit('setPlanHistory', planHistory || []);
  },
  async fetchUsageCount(context, userId) {
    const db = getFirestore();
    const userRef = doc(db, "users", userId);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const usageCount = userSnap.data().usage_count || 0;
      context.commit('setUsageCount', usageCount);
    }
  },
  setUsageCount(context, usageCount) {
    context.commit('setUsageCount', usageCount);
  },
  markAuthStatusChecked({ commit }) {
    commit('setAuthStatusChecked', true);
  },
  // Added inactivity actions
  resetTimer({ commit, dispatch }) {
    commit(CLEAR_TIMEOUT);
    const timeout = setTimeout(() => {
      dispatch('logout');
    }, INACTIVITY_TIMEOUT);
    commit(SET_TIMEOUT, timeout);
  },
  async logout({ commit }) {
    const auth = getAuth(); // Get the auth instance
    try {
      await signOut(auth);
      commit('setUserData', null);  // Reset user data in the state
      console.log("User signed out successfully");
      if (router.currentRoute.path !== '/') {
        router.push('/');
      }
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }
};
