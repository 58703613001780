<template>
    <div>
        <v-btn color="error" @click="logout">
            Logout
        </v-btn>
    </div>
</template>
  
<script>
import { getAuth, signOut } from 'firebase/auth';

export default {
    name: 'Logout',
    methods: {
        async logout() {
            const auth = getAuth();
            try {
                await signOut(auth);
                this.$store.dispatch('setUserData', null);
                if (this.$route.path !== '/') {
                    this.$router.push('/');
                }
            } catch (error) {
                console.error('Error signing out: ', error);
            }
        }
    }
};
</script>
  