import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // primary: colors.indigo.darken3,
        // primary: "#000620",
        // primary: "#1A1449",
        primary: "#1A1449",
        // primary: colors.blue.darken4,
        // primary: colors.amber.darken1,
        secondary: colors.blueGrey.darken3,
        // secondary: "#000620",
        // secondary: colors.green.accent2,
        accent: colors.shades.black,
        goldText: "#EBB428"
        // goldText: colors.amber.darken1
      }
    }
  }
});
