<template>
  <div>
    <div class="text-center">
      <v-checkbox v-model="consentGiven"
        label="I consent to sharing my video captions with AI models for blog post generation."
        :rules="[v => !!v || 'You must agree to proceed.']"></v-checkbox>
    </div>
    <v-tabs v-model="activeTab">
      <v-tab>YouTube</v-tab>
      <v-tab>Upload File</v-tab>
      <!-- Consent Checkbox -->
      <v-tab-item>
        <v-text-field v-if="!isMaxed" label="YouTube URL" v-model="youtubeUrl"></v-text-field>
        <!-- Disclaimer Section -->
        <v-alert v-if="!isMaxed" type="info">
          Note: This service is intended for your own YouTube videos that are under an hour in duration.
          Make sure you allow the app to access your YouTube account. You can do so from your account page.
        </v-alert>
        <v-btn v-if="!isMaxed" color="primary" @click="fetchBlogPost"
          :disabled="!isValidYoutubeUrl() || !consentGiven">Create Blog Post</v-btn>
      </v-tab-item>
      <v-tab-item>
        <div>
          <v-file-input label="Upload your audio or video file" accept="audio/*,video/*" :show-size="1000"
            v-model="file"></v-file-input>
          <v-btn :disabled="!file || !consentGiven" @click="submit" color="primary">Upload and Create Blog Post</v-btn>

          <!-- Progress Bar -->
          <v-progress-linear v-if="uploading" v-model="progress" color="success" height="25" striped><strong>Uploading {{
            Math.ceil(progress) }}%</strong></v-progress-linear>

          <!-- The Snackbar -->
          <v-snackbar v-model="snackbar" :timeout="3000" :color="snackbarColor">
            {{ snackbarMessage }}
            <v-btn text @click="snackbar = false">
              Close
            </v-btn>
          </v-snackbar>
        </div>
      </v-tab-item>
    </v-tabs>

    <div v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <span>Creating blog post and transcript...can take a while depending on the size of the video...please be
        patient.</span>
    </div>
    <v-alert v-if="errorMessage" type="error" dense>
      {{ errorMessage }}
    </v-alert>

    <v-card>
      <v-card-title>Blog Post</v-card-title>

      <v-card-actions class="justify-end">
        <v-btn @click="copyContent('blog')" icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-text v-html="blogPost" ref="copyBlogText"></v-card-text>
    </v-card>
    <v-card>
      <v-card-title>Transcript</v-card-title>

      <v-card-actions class="justify-end">
        <v-btn @click="copyContent('transcript')" icon>
          <v-icon>mdi-content-copy</v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-text v-html="transcript" ref="copyTranscriptText"></v-card-text>
    </v-card>

  </div>
</template>
  
<script>
import axios from 'axios'
import { mapGetters } from "vuex";
import { logEvent, getAnalytics } from 'firebase/analytics';


export default {
  data() {
    return {
      consentGiven: false,
      youtubeUrl: '',
      blogPost: '',
      transcript: '',
      errorMessage: null,
      loading: false,
      activeTab: 0,
      file: null,
      uploading: false,
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      progress: 0,
      blogPost: '',
      transcript: '',
    }
  },
  computed: {
    ...mapGetters([
      'isUserAuthenticated',
      'user',
      'usageCount',
      'plan',
      'isOriginalPlanActive',
      'maxUsage',
      'hasExceededUsageLimit',
      'isMaxed'
    ])
  },
  methods: {
    async getSignedUrl() {
      const token = this.user.accessToken;
      const headers = { 'Authorization': `Bearer ${token}` };
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_ADDRESS}/signed_url?bucket_name=${process.env.VUE_APP_GCP_BUCKET_NAME}&blob_name=${this.file.name}`, { headers });
        return response.data;
      } catch (error) {
        console.error("Error getting signed URL: ", error);
        throw error;
      }
    },
    async uploadFile() {
      try {
        this.uploading = true;

        // Step 1: Get Signed URL
        const signedUrl = await this.getSignedUrl();
        // Step 2: Upload file to GCS
        const config = {
          headers: { 'Content-Type': this.file.type },
          onUploadProgress: (event) => {
            this.progress = Math.min(Math.round((event.loaded * 100) / event.total), 98);
          },
        };
        await axios.put(signedUrl, this.file, config);

        this.progress = 100;
        this.uploading = false;
        this.loading = true;
        // Step 3: Inform backend to process the file
        // (Assuming you've modified your backend to accept the blob name in Google Cloud Storage)
        const token = this.user.accessToken;
        const response = await axios.post(`${process.env.VUE_APP_API_ADDRESS}/process_uploaded_file`, { filename: this.file.name }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        // File processed successfully
        this.snackbarMessage = 'File uploaded and processed successfully';
        this.snackbarColor = 'success';
        this.snackbar = true;
        // Emit custom event with data

        this.blogPost = response.data.blog
        this.transcript = response.data.transcript
        this.loading = false;
        this.$store.dispatch('fetchUsageCount', this.user.uid);
      } catch (error) {
        console.error("Error uploading the file: ", error);

        this.uploading = false;
        this.snackbarMessage = 'There was an error uploading the file';
        this.snackbarColor = 'error';
        this.snackbar = true;
        this.progress = 0;
        this.loading = false;
      }
    },
    submit() {
      if (!this.file) {
        this.snackbarMessage = 'Please select a file first.';
        this.snackbarColor = 'warning';
        this.snackbar = true;
        return;
      }
      this.uploadFile();
    },
    async copyContent(field) {
      let el; // Declare el here

      try {
        if (field === 'blog') {
          el = this.$refs.copyBlogText; // Assign value here
        }
        else if (field === 'transcript') {
          el = this.$refs.copyTranscriptText; // Assign value here
        }

        // Make sure el is not undefined before proceeding
        if (el) {
          await navigator.clipboard.writeText(el.textContent || el.innerText);

          // Optional: Notify the user that the content has been copied
          this.$toasted.show('Content copied!', {
            type: 'success',
            duration: 2000
          });
        } else {
          console.error('Element not found');
          this.$toasted.show('Failed to copy content!', {
            type: 'error',
            duration: 2000
          });
        }

      } catch (err) {
        console.error('Failed to copy content: ', err);
        // Optional: Notify the user of the error
        this.$toasted.show('Failed to copy content!', {
          type: 'error',
          duration: 2000
        });
      }
    },
    fetchBlogPost() {
      const token = this.user.accessToken;
      const headers = {
        'Authorization': `Bearer ${token}`
      };
      if (!this.isValidYoutubeUrl()) {
        return;
      }
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_ADDRESS}/blog`, {
          video_url: this.youtubeUrl,
          type: 'full'
        }, {
          headers: headers
        })
        .then(response => {
          this.blogPost = response.data.blog;
          this.transcript = response.data.transcript;
          this.loading = false;
          // Log the event to Firebase Analytics
          logEvent(getAnalytics(), 'blog_post_created', {
            video_url: this.youtubeUrl
          });
          this.$store.dispatch('fetchUsageCount', this.user.uid);
        })
        .catch(error => {
          console.error(error);
          if (error.response && error.response.status === 403) {
            this.errorMessage = "You do not have permission to access captions for this video since it's not in your YouTube account. Please try again with a different video.";
          } else {
            this.errorMessage = (error.response && error.response.data && error.response.data.error) ? error.response.data.error : "An unexpected error occurred, you may need to authorize the app to access your YouTube account. Do so from the Account page.";
          }
          this.loading = false;
        })
    },
    isValidYoutubeUrl() {
      const pattern = /^(https?:\/\/)?(www\.)?(youtube.com|youtu.be)\/.+/;
      return pattern.test(this.youtubeUrl);
    }
  }
}
</script>