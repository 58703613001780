import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import store from "@/store";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Main"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: {
      title: "About"
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
    meta: {
      title: "Terms"
    }
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
    meta: {
      title: "Privacy"
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      title: "Register"
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login"
    }
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue"),
    meta: {
      title: "Account", requiresAuth: true
    }
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      title: "Admin",
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: "/pricing",
    name: "Pricing",
    component: () => import("../views/Pricing.vue"),
    meta: {
      title: "Pricing"
    }
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import("../components/PasswordReset.vue"),
    meta: {
      title: "PasswordReset"
    }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import("../views/VerifyEmail.vue"),
    meta: {
      title: "VerifyEmail"
    }
  },
  {
    path: '/success',
    name: 'SuccessPage',
    component: () => import("../views/SuccessPage.vue"),
    meta: {
      title: "SuccessPage"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

// The below code is used for titles and meta tags as per: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
router.beforeEach(async (to, from, next) => {
  // Wait for the auth status to be checked before deciding on navigation
  const waitAuthCheck = () => new Promise(resolve => {
    if (store.state.authStatusChecked) {
      resolve();
    } else {
      // Check every 100ms if the auth status has been checked
      const interval = setInterval(() => {
        if (store.state.authStatusChecked) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    }
  });

  await waitAuthCheck();
  // Check if the route requires the user to be authenticated
  const requiresAuth = to.matched.some(record => record.meta && record.meta.requiresAuth);
  const isAuthenticated = store.getters.isUserAuthenticated; // Make sure you import your Vuex store here
  // Redirect to login page if the route requires authentication and the user is not authenticated
  if (requiresAuth && !isAuthenticated) {
    return next('/login');
  }

  // Check if the route requires admin user
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  // Redirect to home if not an admin
  if (requiresAdmin && !store.getters.isUserAdmin) {
    return next('/');
  }
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    // ← here ↓
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  store.dispatch('resetTimer');
  next();
});

export default router;
