<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="video-container">
            <iframe
              :src="videoUrl"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    props: {
      videoId: {
        type: String,
        required: true,
      },
    },
    computed: {
      videoUrl() {
        return `https://www.youtube.com/embed/${this.videoId}`;
      },
    },
  };
  </script>
  
  <style scoped>
  .video-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  </style>
  